export default class JourneyService {
  constructor(api) {
    this.api = api;
  }

  async createJourney(data) {
    try {
      let res = await this.api.createJourney({
        ...data,
        journey_days: data.journey_days.map(journey => {
          return {
            ...journey,
            course_day: journey.course_day.id
          };
        })
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateJourney(data) {
    try {
      let res = await this.api.updateJourney(data.id, {
        ...data,
        journey_days: data.journey_days.map(journey => {
          return {
            ...journey,
            course_day: journey.course_day.id
          };
        })
      });
      return res
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getJourney(id) {
    try {
      let res = await this.api.getJourney(id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async removeJourney(id) {
    try {
      let res = await this.api.removeJourney(id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async journeys(query) {
    try {
      let res = await this.api.journeys(query);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setPresentie(journeyID, ID, needRemove) {
    try {
      let res = await this.api.setPresentie(journeyID, {
        exam: ID,
        need_remove: needRemove
      })
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
