<template lang="pug">
app-dialog(v-model="showing" :title="title")
  template(#dialog-action)
    slot(name="activator" :open="open")
      app-button(outlined @click.native="open") journey
  template(#dialog-body)
    base-preloader(v-if="loading")
    journey-body(
      v-else
      :formErrors="formErrors"
      :activeCity="activeCity"
      :journey="formData"
      @click:course-day="addCourseDay"
      @click:remove="removeJourneyDay"
      @click:update="updateCourseDay"
      @update:sections="updateSections"
      @update:section="updateSection"
      @update:journeyDay="updateJourneyDay"
      @update:internal_name="formData.internal_name = $event"
      @update:selectedSections="updateSelectedSections"
    )
  template(#dialog-footer)
    journey-delete(:editable="editable" :candidates-count="candidatesOnJourney" @submit="remove")
    v-spacer

    app-button(color="#f4f7f9" hide-shadow @click.native="toggle").mr-3
      span.text-label Cancel
    journey-submit(
      :btn-text="btnApply"
      :editable="editable"
      :form-data="formData"
      :form-errors="formErrors"
      :candidates-count="candidatesOnJourney"
      @submit="submit"
    )
</template>

<script>
import {DeleteMS} from '@/util'
import showingMixin from '@/mixins/showing.mixin'
import FormErrors from '@/util/form-errors'
import CourseJourneyValidator from "../../core/validation/courseJourneyValidator.js"
import courseService from '@/app/admin/models/courseService'
import courseAPI from '@/services/api/api-courses'
import Svc from "../../core/journey-service"
import api from "@/services/api/api-journey"
import errorsMixin from '@/mixins/errors.mixin.js'
import BASE_COURSE_JOURNEY from "@/app/admin/modules/course_managment/core/baseCourseJourney"

export default {
  mixins: [showingMixin, errorsMixin],

  props: {
    journeyItemID: Number,
    editable: Boolean,
    activeCity: Number
  },

  data: () => ({
    formErrors: new FormErrors(),
    formData: BASE_COURSE_JOURNEY(),
    loading: false,
    candidatesOnJourney: 0,
    removeDescr: 'Are you sure you want to remove course journey?'
  }),

  computed: {
    title() {
      return this.editable ? 'Edit candidate journey' : 'Create candidate journey'
    },
    btnApply() {
      return this.editable ? 'Save' : 'Create'
    }
  },

  watch: {
    showing(value) {
      if (!value) this.clear()
      if (this.editable && value) this.fillJourneyData()
    }
  },

  methods: {
    async fillJourneyData() {
      if (!this.journeyItemID) return
      let journey = await this.getJourney(this.journeyItemID)
      if (!journey) return
      this.formData.id = journey.id
      this.formData.internal_name = journey.internal_name
      this.candidatesOnJourney = journey.candidates_count
      for (let i = 0; i < journey.journey_days.length; i++) {
        this.fillCourseDay(journey.journey_days[i])
      }
    },

    open() {
      this.updateShowing(true)
    },

    validate() {
      let valid = new CourseJourneyValidator(this.formData, this.formErrors).isValid()
      if(!valid) {
        if(this.formErrors.hasError('journey_days_count')) this.$notify({text: 'Please, select course', type: 'error'})
        else this.$notify({text: 'Invalid fields', type: 'error'})
      }
      return valid
    },

    async submit() {
      if (this.editable) await this.update()
      else await this.create()
    },

    addCourseDay(val) {
      if (this.formData.journey_days.some(journey => journey.course_day.id === val.id)) return
      this.formData.journey_days.push({
        course_day: val,
        sections: val.course_day_sections.map(section => section.id),
        course_start_time: null,
        course_end_time: null
      })
    },

    async updateCourseDay(payload) {
      if (!this.formData.journey_days && !payload) return
      let courseDayItem = payload.data;
      const courseDay = this.formData.journey_days.find(journey => journey.course_day.id === payload.id)
      let courseDayInfo = await this.getCourseDayInfo(courseDayItem.id)
      if (courseDay) {
        let courseDayIndex = this.formData.journey_days.indexOf(courseDay)
        this.formData.journey_days[courseDayIndex].sections = [
          ...courseDayItem.course_day_sections.map(section => section.id)
        ]
        this.formData.journey_days[courseDayIndex].course_day = {
          ...this.formData.journey_days[courseDayIndex].course_day,
          ...courseDayInfo
        }
      }
    },

    fillCourseDay(journeyDay) {
      this.formData.journey_days.push({
        id: journeyDay.id,
        course_day: journeyDay.course_day,
        sections: journeyDay.sections,
        course_start_time: DeleteMS(journeyDay.course_start_time),
        course_end_time: DeleteMS(journeyDay.course_end_time)
      })
    },

    updateSections({index, sections}) {
      this.formData.journey_days[index].sections = sections
    },

    updateSection({index, val}) {
      this.formData.journey_days[index].course_day.course_day_sections[val.index].leader = val.section.leader
    },

    updateJourneyDay({index, field, val}) {
      this.formData.journey_days[index][field] = val
    },

    removeJourneyDay(index) {
      this.formData.journey_days.splice(index, 1)
    },

    updateSelectedSections({val, index}) {
      this.formData.journey_days[index].sections = val
    },

    async getJourney(ID) {
      try {
        this.loading = true
        let res = await new Svc(api).getJourney(ID)
        return res
      } catch (error) {
        this.$notify({text: 'Error', type: 'error'})
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.loading = false
      }
    },

    async create() {
      try {
        this.loading = true
        let res = await new Svc(api).createJourney(this.formData)
        this.updateShowing(false)
        this.clear()
        this.$emit('click:create')
        this.$notify({text: 'Candidate journey created', type: 'success'})
        return res
      } catch (error) {
        this.$notify({text: 'Error', type: 'error'})
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: {
            internal_name: 'internal_name',
            journey_days: 'journey_days'
          },
          nestedErrors: {
            journey_days: {
              course_start_time: 'course_start_time',
              course_end_time: 'course_end_time',
              sections: 'sections'
            }
          },
          showErrorModal: false,
        });
      }
      finally {
        this.loading = false
      }
    },

    async update() {
      try {
        this.loading = true
        let res = await new Svc(api).updateJourney(this.formData)
        this.updateShowing(false)
        this.clear()
        this.$emit('click:update', res)
        this.$notify({text: 'Candidate journey updated', type: 'success'})
        return res
      } catch (error) {
        this.$notify({text: 'Error', type: 'error'})
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: {
            internal_name: 'internal_name',
            journey_days: 'journey_days'
          },
          nestedErrors: {
            journey_days: {
              course_start_time: 'course_start_time',
              course_end_time: 'course_end_time'
            }
          },
          showErrorModal: false,
        });
      } finally {
        this.loading = false
      }
    },

    async remove() {
      try {
        this.loading = true
        await new Svc(api).removeJourney(this.formData.id)
        this.updateShowing(false)
        this.clear()
        this.$emit('click:remove')
        this.$notify({text: 'Journey removed', type: 'success'})
      } catch (error) {
        this.$notify({text: 'Error', type: 'error'})
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.loading = false
      }
    },

    async getCourseDayInfo(id) {
      try {
        this.loading = true
        let res = await new courseService(courseAPI).getCourseDay(id)
        return res
      } catch (error) {
        this.$notify({text: 'Error', type: 'error'})
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.loading = false
      }
    },

    clear() {
      this.formData = BASE_COURSE_JOURNEY()
      this.formErrors.resetFields()
    }
  },

  components: {
    BasePreloader: () => import('@/components/global/BasePreloader.vue'),
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    journeyBody: () => import('./JourneyBody.vue'),
    appSelect: () => import('@/components/global/actions/BaseSelect.vue'),
    journeySubmit: () => import('./JourneySubmit.vue'),
    journeyDelete: () => import('./JourneyDelete.vue')
  }
}
</script>
