import {REQUIRED} from "@/util/validation-rules"
import {DATE_DEFAULT_REGXP} from "@/util/const"

export default class CourseJourneyValidator {
  constructor(data, validator) {
    this.data = data;
    this.validator = validator;
  }

  validate() {
    this.validator.resetFields();
    return this.isValid();
  }

  isValid() {
    this.validateRequired('internal_name', this.data.internal_name)
    this.validateJourneyDay()
    return this.validator.isValid();
  }

  validateJourneyDay() {
    if(!this.data.journey_days.length) return this.validator.invalidField('journey_days_count', 'Should select at least one course');
    else this.validateJourneyTime()
  }

  validateJourneyTime() {
    let fullErrors = []
    this.data.journey_days.forEach((journey, index) => {
      let errors = {}

      if (!this.data.journey_days[index].sections.length) {
        errors.sections = 'Should be selected at least one session'
      }

      if(!this.data.journey_days[index].course_start_time) {
        errors.course_start_time = 'field required'
      }
      else if(!this.validateTime(this.data.journey_days[index].course_start_time)) {
        errors.course_start_time = 'Not correct time'
      }

      if(!this.data.journey_days[index].course_end_time) {
        errors.course_end_time = 'field required'
      }

      else if(!this.validateTime(this.data.journey_days[index].course_end_time)) {
        errors.course_end_time = 'Not correct time'
      }

      if(this.validator.fields.journey_days) fullErrors = this.validator.fields.journey_days
      fullErrors[index] = errors
      if(Object.keys(errors).length) this.validator.invalidField('journey_days', fullErrors)
    })

  }

  validateTime(val) {
    if(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val)) return true
    return false
  }


  validateRequired(field, data) {
    let match = REQUIRED(data);
    if (match === true) return true;
    this.validator.invalidField(field, match);
    return false;
  }
}
